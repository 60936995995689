.contactbar-main-container {
  position: absolute;
  top: 140px;
  left: 20px;
  width: 3rem;
  height: auto;
}

.contactbar-icon-mail, .contactbar-icon-linkedin, .contactbar-icon-github {
  width: 100%;
  opacity: 0.8;
  padding-top: 10px;
  color: #ffffff;
}

@media Screen and (max-width: 1200px) {
  .contactbar-main-container {
    display: none;
  }
}