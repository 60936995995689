.about-container {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 4rem;
}

/* @media Screen and (max-width: 400px) {
  .about-container {
    padding: 1rem;
  }
} */
