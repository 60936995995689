.main-container-home {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%; 
  height: 87vh;
  padding: 10rem;
}

.two-column-left, .two-column-right {
  width: 50%;
  text-align: center;
  padding: 2rem;
}

.two-column-right {
  align-self: flex-end;
  padding: 0;
  height: 100%;
}

.home-h1 {
  font-size: 7rem;
  font-family: inherit;
  margin: 1rem 0
}

.home-h3 {
  font-size: 2rem;
  font-family: 'Rambla';
}

.develop-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.develop-item {
  font-family: 'Rambla';
  font-size: 1.1rem;
  margin: 0.5rem;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  background-color: #ce7eaf;
  border: 1px solid #ffffff;
}

.image {
    position: absolute;
    bottom: 0;
    right: 10rem;
    height: 75%;
    opacity: 0.9;
    z-index: -1;
  }
  .ornament {
    width: 22rem;
  }

@media Screen and (min-width: 2300px) {
  .image {
    right: 30rem;
  }
} 

@media screen and (min-width: 2100px) {
  .main-container-home {
    padding: 20rem;
  }
  .image {
    right: 25rem;
  }
}

@media screen and (min-width: 1800px) {
  .image {
    height: 80%;
    right: 20rem;
    align-self: flex-end;
  }
}

@media screen and (max-width: 1400px) {
  .image {
    right: 5rem;
  }
}

@media screen and (max-width: 1200px) {
  .main-container-home {
    padding: 2rem;
    margin-left: 0;
  }
  .two-column-left, .two-column-right {
    width: 100%;
  }
  .two-column-left {
    margin-top: 4rem;
  }
  .image {
    position: unset;
    height: 90%;
    margin-top: 5rem;
  }
  .home-h1 {
    font-size: 5rem;
  }
  .ornament {
    width: 18rem;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 500px) {
  .main-container-home {
    padding: 0.5rem;
  }
  .two-column-left {
    margin-top: 1rem;
  }
  .image {
    margin-top: 3rem;
    height: 94%;
  }
}