.navbar {
  height: 8rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navlink-wrapper {
  display: block;
  margin-right: 1rem;
  background-color: #ffffff; 
  border: 1px solid black;
  border-radius: 5px;
}

.navlink-wrapper:hover {
  -webkit-box-shadow: 1px 4px 12px 1px #A3A3A3; 
  box-shadow: 1px 4px 12px 1px #A3A3A3;
}

.navlink-active {
  -webkit-box-shadow: 1px 4px 12px 1px #A3A3A3; 
  box-shadow: 1px 4px 12px 1px #A3A3A3;
}

.navlink-button {
  font-family: 'Rambla';
  padding: 1rem 2rem;
  letter-spacing: 2px;
  font-size: 1.3rem;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: 0;
}

.header-menu-container {
  position: absolute;
  right: 3rem;
  display: none;
  cursor: pointer;
}

.header-menu {
  width: 2.5rem;
}

/* .header-mail-icon {
  width: 3rem;
  margin-left: 3rem;
  border: 1px solid red;
} */

@media screen and (max-width: 1100px) {
  .navbar {
    display: none;
  }
  .header-menu-container {
    display: block;
  }
}

