.main-container-contact {
  justify-content: center;
}

.contact-container {
  width: 60%;
  margin-top: 5rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  height: fit-content;
}

@media Screen and (max-width: 1200px) {
  .contact-container {
    width: 80%;
  }
}

@media Screen and (max-width:450px) {
  .contact-container {
    width: 95%;
  }
}

.contact-link-container {
  margin: 1rem 0; 
  display: flex;
  /* flex-flow: row wrap; */
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.contact-icon {
  width: 3rem;
  padding: 1rem 0;
  margin-right: 2rem;
}

.contact-span-text {
  font-size: 1.2rem;
  color: #333333;
}

/* .contact-link {
  background-color: transparent;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  border: 1px solid black;
  color: inherit;
  border-radius: 5px;
  display: inline-block;
}

.contact-link:hover {
  background-color: #ce7eaf;
} */