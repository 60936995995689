html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: 'Roboto', 'sans-serif';
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
html {
  overflow-x: hidden;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body, div, span {
  margin: 0;
  font-size: 100%;
  font-family: /* -apple-system, BlinkMacSystemFont,  */'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  background: #ce7eaf;
}

/* body {
  background: linear-gradient(131deg, #f8bdfd, #ce7eaf, #30b6bd, #b5dedc, #cedc84);
  background-size: 2000% 2000%;

  -webkit-animation: background 40s ease infinite;
  -moz-animation: background 40s ease infinite;
  animation: background 40s ease infinite;
}

@-webkit-keyframes background {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
}
@-moz-keyframes background {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
}
@keyframes background {
  0%{background-position:0% 81%}
  50%{background-position:100% 20%}
  100%{background-position:0% 81%}
} */

ol, ul {
	list-style: circle;
} 

p, ul, li {
  font-family: 'Marcellus';
  font-size: 1.2rem;
  margin: 1rem 0;
  line-height: 1.8rem;
}

p {
  margin: 0.5rem 0;
}

ul {
  border-left: 5px solid #ce7eaf;
  margin: 1rem 0;
  line-height: 1.2rem;
}

li {
  padding: 0 1.5rem;
} 

h3 {
  font-size: 1.4rem;
  margin-top: 1rem;
}

h4 {
  font-size: 1.1rem;
  margin-top: 1rem;
  font-weight: 500;
}
