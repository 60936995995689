.sidebar-container {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  z-index: 10;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

@media screen and (min-width: 1100px) {
  .sidebar-container {
    display: none;
  }
} 

.sidebar-visible {
  transform: translateX(0);
} 

.sidebar-close {
  width: 2rem;
  cursor: pointer;
}

.sidebar-buttons-container {
  flex-flow: column wrap;
}

.sidebar-link {
  text-decoration: none;
  color: blue;
  border: 1px solid blue;
} 

.sidebar-active {
  text-decoration: none;
  background-color: blue;
}

.sidebar-button-item {
  border: 1px solid black;
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem;
  cursor: pointer;
  font-size: 1.1rem;
  text-transform: uppercase;
  border-radius: 5px;
}

ul
{
list-style: none;
padding: 0;
margin: 0;
}
