.project-image {
  width: 100%;
}

.project-main-container {
  display: flex;
  flex-direction: column;
}

.project-container {
  padding: 2rem;
  margin-top: 3rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
}

.icon-link-container {
  margin-top: 1.3rem;
}

.link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.3rem;
}

.dev-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 1.5rem 0;
}

.dev-items {
  font-size: 0.9rem;
  margin-right: 0.5rem;
  padding: 2px 1rem;
  margin: 0 0.5rem 0.5rem 0;
  border-radius: 15px;
  background-color: transparent;
  border: 1px solid #ffffff;
}

.project-icon {
  width: 1.8rem;
  margin-right: 0.5rem;
}