.main-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: calc(100vh - 8rem);
  padding: 0 10rem;
  margin-top: 3rem;
}

a, a:visited {
  color: #333333;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 2100px) {
  .main-container {
    padding: 0 25rem;
  }
}
 
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .main-container {
    padding: 0 5rem;
  }
}

@media screen and (max-width: 1200px) {
  .main-container {
    padding: 0 2rem;
  }
} 

@media screen and (max-width: 600px) {
  .main-container {
    padding: 0 0.5rem;
  }
} 

.two-column-standard {
  width: 50%;
  padding: 2rem;
}

@media screen and (max-width: 1200px) {
  .two-column-standard {
    width: 100%;
    margin-top: 2rem;
  }
}

.three-column-standard {
  width: 33%;
  padding: 2rem;
}

@media screen and (max-width: 1600px) {
  .three-column-standard {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  .three-column-standard {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .three-column-standard, .two-column-standard {
    padding: 0.5rem;
  }
  .main-container {
    padding: 0;
  }
}
