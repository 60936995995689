.cv-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cv-icon {
  width: 5rem;
}

.cv-container {
  margin: 2rem 0;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
}

.cv-top-container {
  padding: 0.5rem 0;
}

/* @media Screen and (max-width: 1200px) {
  .cv-icon-container:nth-of-type(0) {
    margin-top: 3rem;
    border: 1px solid red;
  }
} */